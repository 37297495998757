.cover {
  position: fixed;
  width: 100%;
  bottom: 10px;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: center;
}

.adBox {
  width: 320px;
  height: 50px;
}

/* @media screen and (min-width: 480px) {
  .cover {
    visibility: hidden;
    z-index: -1;
  }
} */
